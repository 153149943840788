import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppLogo, AppText, AppTitle, FlexDiv } from "../../../../components/ui";
import { BasicLayout } from "../../../../layouts/BasicLayout";
import { mobileSize } from "../../../../util/variables";
import { navigate } from "gatsby";
// import { gift_redeem_emailSwitch } from "../../../../services/mixpanel/gift-flow-events";
import { GoogleSSOButton } from "../../../../components/ui/GoogleSSOButton";
import { AppleSSOButton } from "../../../../components/ui/AppleSSOButton";
import { getUserProfile } from "../../../../services/firebase";
import theme from "../../../../util/theme";
import { gift_page_view, next_page_view } from "../../../../services/mixpanel/gift-flow-events";
// import { US_PRICE_IDS } from "../../../../util/local-price-ids";

export default (data: any) => {
  const { search, state } = data.location;
  const [createAccount, setCreateAccount] = useState(true);
  const handleEmailLogin = () => {
    navigate(`email${search ?? ""}?createAccount=${createAccount ? "true" : "false"}`, {
      state,
    });
  };

  useEffect(() => {
    gift_page_view("GiftLogin");
  }, []);

  const toggleCreateAccount = () => {
    setCreateAccount(prev => !prev);
  };

  const onLoginSuccess = async () => {
    const response = await getUserProfile();
    const storedAnswers = localStorage.getItem("onboardingAnswers");
    console.log(response);
    if (response.subscription_status !== "No active subscription") {
      // go straight gift redeem
      alert(
        "You’ve already purchased this product. Please contact info@imprintapp.com if you have any questions or issues.",
      );

      return;
    }

    if (storedAnswers) {
      next_page_view("GiftRedeem");
      navigate("/gift/GiftLogin");
    } else {
      next_page_view("GiftRedeem");
      navigate(`/onboarding/questions/?fromGift=true`, {
        state,
      });
    }
  };

  return (
    <BasicLayout noIndex>
      <Content>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <FlexDiv direction="column" align="center" gap={12}>
          <AppTitle style={{ marginBottom: 48 }}>
            {createAccount ? "Create an Account" : "Log in"}
          </AppTitle>
          <AppleSSOButton
            autoHandleAnalytics={{ webflow: "WebGiftRedeem" }}
            onLoginSuccess={onLoginSuccess}
          />
          <GoogleSSOButton
            autoHandleAnalytics={{ webflow: "WebGiftRedeem" }}
            onLoginSuccess={onLoginSuccess}
          />
          <EmailButton onClick={handleEmailLogin}>Continue with Email</EmailButton>
          <ChangeFormText>
            {createAccount ? "Already have an Imprint account?" : "Don't have an Imprint account?"}
            <ChangeFormButton type="button" onClick={toggleCreateAccount}>
              {createAccount ? "Log in" : "Create one"}
            </ChangeFormButton>
          </ChangeFormText>
        </FlexDiv>
      </Content>
    </BasicLayout>
  );
};

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;

const EmailButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 327px;
  max-width: 100%;
  padding: 14px;
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
  color: black;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  height: 100vh;
  margin: 0 auto;
  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

const ChangeFormButton = styled.button`
  border: none;
  background: none;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 14px;
  color: ${theme.SLATE_COLOR_2};
  text-decoration: underline;
  font-weight: bold;
`;

const ChangeFormText = styled(AppText as any)`
  font-size: 14px;
  color: ${theme.SLATE_COLOR_2};
  text-align: center;
  margin-top: 16px;
`;
